import { cloneDeep } from 'lodash';
import { call, put, select, takeEvery, takeLeading } from 'typed-redux-saga';
import { v4 } from 'uuid';

import { getResourceManager } from '../../resources';
import { iDBActService } from '../../resources/iDBAct';
import { IAction } from '../../types/redux';
import {
  countDeltaToApply,
  prepareDeltaEventsBeforeCount,
} from '../../utils/EventTrain/countDeltaToApply';
import { getEventTrain, getSyncState, initSyncProcess } from '../../utils/SyncGroup/SyncGroup';
import { innerApplyEvents } from '../editor';
import { TAppState } from '../types';

import { setSyncEnable } from './sync.actions';
import {
  DESTROY_SYNC,
  INIT_SYNC,
  IOnDeltaReceivedAction,
  ON_DELTA_RECEIVED,
  SET_NO_AUTH,
  START_SYNC,
  SYNC_STATE_ASK_DELTA,
} from './sync.types';

function syncStateAskDelta() {
  console.log('syncStateAskDelta');
}

function* startSyncWorker() {
  try {
    const user = yield* select((state: TAppState) => state.user);
    if (!user.isAuth) {
      return;
    }

    const { project } = yield* select((state: TAppState) => state.editor);
    if (!project) {
      return;
    }
    yield* call(initSyncProcess, project.id);

    const syncState = yield* call(getSyncState, project.id);
    const eventTrain = yield* call(getEventTrain, project.id);
    eventTrain.setIsBlocked({ isBlocked: false });

    const manager = getResourceManager();
    const response = yield* call(
      manager.ProjectService.checkIfExists.bind(manager.ProjectService),
      {
        projectId: project.id,
      },
    );

    if (!syncState.isSyncEnabled && !response.doesProjectExist) {
      return;
    }

    if (!syncState.isSyncEnabled && response.doesProjectExist) {
      if (!syncState.lastUpdateId) {
        console.error('Cannot continue sync, local version can be different from remote');
        return;
      }

      syncState.isSyncEnabled = true;
      const stateToSave = syncState.toStorage();
      yield* call(iDBActService.saveOne, stateToSave);
      yield* put(setSyncEnable({ isEnable: true }));
      eventTrain.run();
      return;
    }

    if (syncState.isSyncEnabled && response.doesProjectExist) {
      yield* put(setSyncEnable({ isEnable: true }));
      eventTrain.run();
      return;
    }
  } catch (error) {
    console.log('Error during startSyncWorker', error);
  }
}

function* destroySyncWorker() {
  try {
    const { project } = yield* select((state: TAppState) => state.editor);
    if (!project) {
      return;
    }

    const projectId = project.id;

    const user = yield* select((state: TAppState) => state.user);

    yield* call(initSyncProcess, projectId);

    if (!user.isAuth) {
      return;
    }

    const manager = getResourceManager();
    const response = yield* call(
      manager.ProjectService.deleteOneById.bind(manager.ProjectService),
      projectId,
    );

    if (!response.isOk) {
      console.error('Cannot destroy project, 500 server response');
      return;
    }

    const syncState = yield* call(getSyncState, project.id, { isBlocked: !user.isAuth });
    const eventTrain = yield* call(getEventTrain, project.id, { isBlocked: !user.isAuth });

    yield* call(eventTrain.totalStop.bind(eventTrain));

    syncState.collectChanges({ isSyncEnabled: false, lastUpdateId: null });
    syncState.commitChanges();
    const stateToSave = syncState.toStorage();
    yield* call(iDBActService.saveOne, stateToSave);

    yield* put(setSyncEnable({ isEnable: false }));
  } catch (error) {
    console.log('Error during destroySyncWorker', error);
  }
}

function* initSyncWorker() {
  try {
    const { project } = yield* select((state: TAppState) => state.editor);
    if (!project) {
      return;
    }

    const projectId = project.id;

    const user = yield* select((state: TAppState) => state.user);

    yield* call(initSyncProcess, projectId);

    if (!user.isAuth) {
      return;
    }

    const manager = getResourceManager();
    const response = yield* call(manager.SyncService.initSync.bind(manager.SyncService), {
      eventId: v4(),
      project,
    });

    if (!response.isOk) {
      console.error('Cannot start sync, 500 server response');
      return;
    }

    const syncState = yield* call(getSyncState, project.id, { isBlocked: !user.isAuth });

    syncState.collectChanges({ isSyncEnabled: true, lastUpdateId: response.lastUpdateId });
    syncState.commitChanges();
    const stateToSave = syncState.toStorage();
    yield* call(iDBActService.saveOne, stateToSave);

    yield* put(setSyncEnable({ isEnable: true }));

    yield* put({ type: START_SYNC });
  } catch (error) {
    console.log('Error during initSyncWorker', error);
  }
}

function* setNoAuthEffect() {
  const { project } = yield* select((state: TAppState) => state.editor);
  if (!project) {
    return;
  }

  const projectId = project.id;

  const eventTrain = yield* call(getEventTrain, projectId);
  eventTrain.setIsBlocked({ isBlocked: true });
}

function* deltaWorker(action: IAction<IOnDeltaReceivedAction>) {
  try {
    const { payload } = action;

    if (!payload || !payload?.remote || !payload?.local) {
      return;
    }

    const { conflictState } = yield* select((state: TAppState) => state.sync);

    const { local } = payload;

    const remote = prepareDeltaEventsBeforeCount({
      events: payload.remote,
      notes: payload.notes,
    });

    const registry = cloneDeep(conflictState.registry);
    const conflicts = cloneDeep(conflictState.conflicts);
    const dispenser = cloneDeep(conflictState.dispenser);

    const results = countDeltaToApply({
      dispenser,
      registry,
      conflicts,
      remoteEvents: remote,
      localEvents: local,
    });

    const areConflicts = Object.keys(results.conflicts).length;
    console.log('areConflicts', areConflicts);

    if (areConflicts) {
      // yield put(
      //   updateConflictState({
      //     ...results,
      //     remoteEvents: remote,
      //     localEvents: local,
      //   }),
      // );
      /*
        Define latest event in conflicts
        if it's client then sent to the server
        if it's server then save on the client
       */
      // Object.values(results.conflicts).forEach((conflict) => {
      //   conflict.localEvents, conflict.remoteEvents;
      // });
    } else {
      for (const event of remote) {
        yield put(innerApplyEvents(event));
      }

      const {
        editor: { project },
      } = yield select((state: TAppState) => state);

      if (!project) {
        return;
      }

      const eventTrain = yield* call(getEventTrain, project.id);

      eventTrain.setConflictLastIdToLastUpdateId();
      eventTrain.run();
    }
  } catch (error) {
    console.log('Error during deltaWorker', error);
  }
}

export function* syncSagaWatch() {
  yield* takeEvery(SYNC_STATE_ASK_DELTA, syncStateAskDelta);
  yield* takeEvery(INIT_SYNC, initSyncWorker);
  yield* takeEvery(DESTROY_SYNC, destroySyncWorker);

  yield* takeLeading(START_SYNC, startSyncWorker);

  yield* takeEvery(SET_NO_AUTH, setNoAuthEffect);
  yield* takeEvery(ON_DELTA_RECEIVED, deltaWorker);
}
