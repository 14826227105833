import { createReducer } from '@reduxjs/toolkit';
import { TEntityRef, TAct } from '@ws/shared/types';
import { getSha1Hash } from '@ws/shared/utils';
import CyrillicToTranslit from 'cyrillic-to-translit-js';

import { IAction } from '../../types/redux';
import { expandArrayWithItemsAtPosition } from '../../utils/array';
import {
  CHILD_MOVE_TYPE,
  COLLECTION_CREATE,
  COLLECTION_UPDATE_CHILD,
  ICollectionChildUpdateAct,
  ICollectionCreateAct,
  INodeMetaUpdateAct,
  INodeParentUpdateAct,
  INodeRemoveAct,
  INoteContentUpdateAct,
  INoteCreateAct,
  IProjectMetaUpdateAct,
  NODE_META_UPDATE,
  NODE_PARENT_UPDATE,
  NODE_REMOVE,
  NOTE_CREATE,
  NOTE_UPDATE_CONTENT,
  PROJECT_META_UPDATE,
} from '../../utils/events/act.types';

import {
  IEditorState,
  IEventsApplyAction,
  INNER_APPLY_EVENTS,
  INodeSelectAction,
  IProjectSetAction,
  ISecondaryNodeSelectAction,
  PROJECT_CLEAN,
  PROJECT_SET,
  SELECT_NODE,
  SELECT_SECONDARY_NODE,
} from './editor.types';
import { removeFromArrayIfItemExists } from '../../utils/array/removeFromArrayIfItemExists';

const INITIAL_STATE: IEditorState = {
  project: null,
  selected: null,
  secondarySelected: null,
};

export const editorReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(PROJECT_SET, (state: IEditorState, action: IAction<IProjectSetAction>) => {
      state.project = action.payload?.project || null;
    })
    .addCase(PROJECT_CLEAN, (state: IEditorState) => {
      state.project = null;
      state.selected = null;
    })
    .addCase(SELECT_NODE, (state: IEditorState, action: IAction<INodeSelectAction>) => {
      state.selected = action.payload?.selected || null;
    })
    .addCase(
      SELECT_SECONDARY_NODE,
      (state: IEditorState, action: IAction<ISecondaryNodeSelectAction>) => {
        state.secondarySelected = action.payload?.secondarySelected || null;
      },
    )
    .addCase(
      INNER_APPLY_EVENTS,
      ({ project }: IEditorState, action: IAction<IEventsApplyAction>) => {
        console.log('INNER_APPLY_EVENTS');
        if (!project) {
          return;
        }

        const acts = action.payload?.acts;
        if (!acts) {
          return;
        }

        const additionalTime = `${Date.now()}`;
        const transactionUpdateTime = (action.payload?.time as string) || additionalTime;
        project.updatedAt = transactionUpdateTime;

        for (let i = 0; i < acts.length; i += 1) {
          const currentAct = acts[i];

          switch (currentAct.type) {
            case PROJECT_META_UPDATE: {
              const { payload } = currentAct as TAct<IProjectMetaUpdateAct>;
              if (!payload) {
                break;
              }

              project.meta = { ...project.meta, ...payload.meta };

              break;
            }
            case NODE_PARENT_UPDATE: {
              const { payload } = currentAct as TAct<INodeParentUpdateAct>;
              if (!payload) {
                break;
              }

              const { ref, parentId } = payload;

              if (ref.type === 'note') {
                const oldNote = project.notes[ref.id];
                oldNote.updatedAt = transactionUpdateTime;
                oldNote.parentId = parentId;
                break;
              }

              if (ref.type === 'collection') {
                const oldCollection = project.collections[ref.id];
                oldCollection.updatedAt = transactionUpdateTime;
                oldCollection.parentId = parentId;
              }

              break;
            }
            case NODE_META_UPDATE: {
              const { payload } = currentAct as TAct<INodeMetaUpdateAct>;
              if (!payload) {
                break;
              }

              const { ref, meta } = payload;

              if (ref.type === 'note') {
                const oldNote = project.notes[ref.id];
                oldNote.updatedAt = transactionUpdateTime;
                oldNote.meta = { ...oldNote.meta, ...meta };
                break;
              }

              if (ref.type === 'collection') {
                const oldCollection = project.collections[ref.id];
                oldCollection.updatedAt = transactionUpdateTime;
                oldCollection.meta = { ...oldCollection.meta, ...meta };
              }

              if (ref.id === project.root && meta.name) {
                project.meta.name = meta.name;

                const trans = new CyrillicToTranslit({ preset: 'ru' });
                project.meta.slug = trans.transform(meta.name);
              }

              break;
            }
            case NOTE_UPDATE_CONTENT: {
              const { payload } = currentAct as TAct<INoteContentUpdateAct>;
              if (
                !payload ||
                typeof payload.entityId === 'undefined' ||
                typeof payload.content === 'undefined'
              ) {
                break;
              }

              const noteToUpdate = project.notes[payload.entityId];
              if (!noteToUpdate) {
                throw new Error('No note to update content');
              }

              noteToUpdate.content = payload.content;
              noteToUpdate.hash = payload.hash;
              noteToUpdate.updatedAt = transactionUpdateTime;

              project.notes[payload.entityId] = noteToUpdate;

              break;
            }
            case COLLECTION_UPDATE_CHILD: {
              const { payload } = currentAct as TAct<ICollectionChildUpdateAct>;
              if (
                !payload ||
                typeof payload.entityId === 'undefined' ||
                typeof payload.child === 'undefined'
              ) {
                break;
              }

              const collectionToUpdate = project.collections[payload.entityId];
              if (!collectionToUpdate) {
                throw new Error(`No collection with id ${payload.entityId} to update a child`);
              }

              let updatedChildren: TEntityRef[] = [...collectionToUpdate.children];

              switch (payload.move) {
                case CHILD_MOVE_TYPE.ADD: {
                  updatedChildren = removeFromArrayIfItemExists(updatedChildren, payload.child);
                  updatedChildren = expandArrayWithItemsAtPosition(
                    updatedChildren,
                    [payload.child],
                    payload.position,
                  );
                  break;
                }
                case CHILD_MOVE_TYPE.REMOVE: {
                  updatedChildren = removeFromArrayIfItemExists(updatedChildren, payload.child);
                  break;
                }
                default:
                  throw new Error(`No such move type ${payload.move} to update collection`);
              }

              collectionToUpdate.children = updatedChildren;
              collectionToUpdate.hash = getSha1Hash(JSON.stringify(updatedChildren));
              collectionToUpdate.updatedAt = transactionUpdateTime;

              project.collections[payload.entityId] = collectionToUpdate;

              break;
            }
            case NOTE_CREATE: {
              const { payload } = currentAct as TAct<INoteCreateAct>;
              if (!payload || !payload.note) {
                break;
              }

              payload.note.updatedAt = transactionUpdateTime;
              project.notes[payload.note.id] = payload.note;

              break;
            }
            case COLLECTION_CREATE: {
              const { payload } = currentAct as TAct<ICollectionCreateAct>;
              if (!payload || !payload.collection) {
                break;
              }

              payload.collection.updatedAt = transactionUpdateTime;
              project.collections[payload.collection.id] = payload.collection;

              break;
            }
            case NODE_REMOVE: {
              const { payload } = currentAct as TAct<INodeRemoveAct>;
              if (!payload || !payload.ref) {
                break;
              }

              const { ref } = payload;

              if (ref.type === 'note') {
                delete project.notes[ref.id];
                break;
              } else if (ref.type === 'collection') {
                delete project.collections[ref.id];
              }

              break;
            }
            default:
              console.error(`Unknown event with type ${currentAct.type}`);
          }
        }
      },
    );
});
