import styles from '../NoteName/NoteName.module.css';

interface INoteNameProps {
  children: string;

  onClick(): void;
}

export function NoteName({ children, onClick }: INoteNameProps) {
  return (
    <div className={styles['note-name']} onClick={onClick}>
      {children}
    </div>
  );
}
