import { CompilerContent } from '@ws/shared/components';
import { TFormatted } from '@ws/shared/types';

import { useNote } from '../../../hooks';
import { InCompilerEditor } from '../../Editor';

import { CompilerTemplate } from './CompilerTemplate';
import { CompleteButton } from './CompleteButton';
import { EditButton } from './EditButton';
import { NoteName } from './NoteName/NoteName';
import { useNavigate, useParams } from 'react-router-dom';

export interface ICompiledContainerProps {
  entity: TFormatted;
  isSelected: boolean;

  onEntityClick(): void;

  isEditing: boolean;

  onEdit(): void;
}

export function CompilerContainer({
  entity,
  isSelected,
  onEntityClick,
  isEditing,
  onEdit,
}: ICompiledContainerProps) {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const note = useNote(entity.id);

  function handleNoteHeaderClick() {
    navigate(`/project/${projectId}/node/${entity.id}`);
  }

  return (
    <>
      <CompilerTemplate
        isEditing={isEditing}
        content={<CompilerContent entity={entity} onClick={onEntityClick} />}
        editor={note && isEditing && <InCompilerEditor note={note} />}
        noteName={<NoteName onClick={handleNoteHeaderClick}>{entity.originalName}</NoteName>}
        editButton={isSelected && !isEditing && <EditButton onClick={onEdit} />}
        completeButton={note && isEditing && <CompleteButton isDesktopOnly onClick={onEdit} />}
      />
      {note && isEditing && <CompleteButton isNotDesktop onClick={onEdit} />}
    </>
  );
}
