import { TEntityRef } from '@ws/shared/types';

export function removeFromArrayIfItemExists(arrayToClean: TEntityRef[], itemToRemove: TEntityRef) {
  const cleanArray: TEntityRef[] = [...arrayToClean];

  const idx = cleanArray.findIndex((item) => item.id === itemToRemove.id);

  if (idx === -1) {
    return cleanArray;
  }

  cleanArray.splice(idx, 1);

  return cleanArray;
}
