import { TActExt, TEvent } from '@ws/shared/types';
import { getDeepCopy } from '@ws/shared/utils';
import { v4 } from 'uuid';

import { TConflict, TConflictDict, TDispenser, TEventRegistry } from '../../redux/sync/sync.types';
import { TShortNoteDict } from '../../resources/syncService';
import {
  COLLECTION_CREATE,
  COLLECTION_UPDATE_CHILD,
  ICollectionChildUpdateAct,
  ICollectionCreateAct,
  INodeMetaUpdateAct,
  INodeParentUpdateAct,
  INodeRemoveAct,
  INoteContentUpdateAct,
  INoteCreateAct,
  IProjectMetaUpdateAct,
  NODE_META_UPDATE,
  NODE_PARENT_UPDATE,
  NODE_REMOVE,
  NOTE_CREATE,
  NOTE_UPDATE_CONTENT,
  PROJECT_META_UPDATE,
} from '../events/act.types';

function getEntityIdFromAct(act: TActExt) {
  switch (act.type) {
    case PROJECT_META_UPDATE: {
      return (act as TActExt<IProjectMetaUpdateAct>).payload.projectId;
    }

    case NOTE_CREATE: {
      return (act as TActExt<INoteCreateAct>).payload.note.id;
    }

    case COLLECTION_CREATE: {
      return (act as TActExt<ICollectionCreateAct>).payload.collection.id;
    }

    case NODE_META_UPDATE: {
      return (act as TActExt<INodeMetaUpdateAct>).payload.ref.id;
    }

    case NODE_PARENT_UPDATE: {
      return (act as TActExt<INodeParentUpdateAct>).payload.ref.id;
    }

    case NOTE_UPDATE_CONTENT: {
      return (act as TActExt<INoteContentUpdateAct>).payload.entityId;
    }

    case COLLECTION_UPDATE_CHILD: {
      return null;
      return (act as TActExt<ICollectionChildUpdateAct>).payload.entityId;
    }

    case NODE_REMOVE: {
      return (act as TActExt<INodeRemoveAct>).payload.ref.id;
    }

    default: {
      return '';
    }
  }
}

function processAct(accum: TEventRegistry, label: string, eventIdx: number) {
  return (act: TActExt) => {
    const id = getEntityIdFromAct(act);

    if (!id) {
      return;
    }

    if (!accum[id]) {
      accum[id] = {};
    }

    if (!accum[id][act.type]) {
      accum[id][act.type] = {
        conflictId: null,
        isUpdated: true,
        idxsOfLocals: [],
        idxsOfRemotes: [],
      };
    }

    if (label === 'local') {
      if (accum[id][act.type].idxsOfLocals.includes(eventIdx)) {
        return;
      }
      accum[id][act.type].idxsOfLocals.push(eventIdx);
      accum[id][act.type].isUpdated = true;
    }

    if (label === 'remote') {
      if (accum[id][act.type].idxsOfRemotes.includes(eventIdx)) {
        return;
      }
      accum[id][act.type].idxsOfRemotes.push(eventIdx);
      accum[id][act.type].isUpdated = true;
    }
  };
}

export function registerChanges(
  registry: TEventRegistry = {},
  localList: TEvent[],
  remoteList: TEvent[],
) {
  const len = Math.max(localList.length, remoteList.length);
  for (let i = 0; i < len; i += 1) {
    const local = localList[i];
    if (local) {
      local.acts.forEach(processAct(registry, 'local', i));
    }

    const remote = remoteList[i];
    if (remote) {
      remote.acts.forEach(processAct(registry, 'remote', i));
    }
  }
}

export function collectConflicts({
  conflictsCollection,
  registry,
  dispenser,
  localEvents,
  remoteEvents,
  applyOnClient,
  applyOnServer,
}: {
  conflictsCollection: TConflictDict;
  registry: TEventRegistry;
  dispenser: TDispenser;
  localEvents: TEvent[];
  remoteEvents: TEvent[];
  applyOnClient: TEvent[];
  applyOnServer: TEvent[];
}) {
  Object.entries(registry).forEach(([_entityId, eventTypeDict]) => {
    Object.entries(eventTypeDict).forEach(([eventType, conflictSource]) => {
      if (conflictSource.idxsOfRemotes.length > 0 && conflictSource.idxsOfLocals.length > 0) {
        if (!conflictSource.isUpdated) {
          return;
        }
        conflictSource.isUpdated = false;

        if (!conflictSource.conflictId) {
          const conflictId = v4();

          conflictSource.conflictId = conflictId;

          conflictsCollection[conflictId] = {
            id: conflictId,
            eventType,
            remoteEvents: [],
            localEvents: [],
            resolvedTo: null,
          };
        }

        conflictSource.idxsOfRemotes.forEach((idx) => {
          if (!dispenser[`remote_${idx}`]) {
            dispenser[`remote_${idx}`] = conflictSource.conflictId!;
            conflictsCollection[conflictSource.conflictId!].remoteEvents.push(remoteEvents[idx]);
          }
        });

        conflictSource.idxsOfLocals.forEach((idx) => {
          if (!dispenser[`local_${idx}`]) {
            dispenser[`local_${idx}`] = conflictSource.conflictId!;
            conflictsCollection[conflictSource.conflictId!].localEvents.push(localEvents[idx]);
          }
        });
      } else {
        if (conflictSource.idxsOfLocals.length && conflictSource.idxsOfRemotes.length) {
          return;
        }

        if (conflictSource.idxsOfLocals.length) {
          for (const idx of conflictSource.idxsOfLocals) {
            applyOnServer.push(localEvents[idx]);
          }
        }

        if (conflictSource.idxsOfRemotes.length) {
          for (const idx of conflictSource.idxsOfRemotes) {
            applyOnClient.push(remoteEvents[idx]);
          }
        }
      }
    });
  });
}

export function autoResolve(conflicts: TConflictDict) {
  Object.values(conflicts).forEach((conflict: TConflict) => {
    const localEvent = conflict.localEvents.slice(-1)[0];
    const remoteEvent = conflict.remoteEvents.slice(-1)[0];

    switch (conflict.eventType) {
      case PROJECT_META_UPDATE:
      case NODE_META_UPDATE: {
        if (localEvent.time > remoteEvent.time) {
          conflict.resolvedTo = 'local';
        } else {
          conflict.resolvedTo = 'remote';
        }
        return;
      }

      // While no manual conflict resolving we choose automatically the last update
      case NOTE_UPDATE_CONTENT: {
        if (localEvent.time > remoteEvent.time) {
          conflict.resolvedTo = 'local';
        } else {
          conflict.resolvedTo = 'remote';
        }
        return;
      }

      case NODE_PARENT_UPDATE: {
        return;
      }

      case COLLECTION_UPDATE_CHILD: {
        return;
      }

      case NODE_REMOVE: {
        conflict.resolvedTo = 'no_moves';
        return;
      }

      default: {
        return;
      }
    }
  });

  return conflicts;
}

export function countDeltaToApply({
  registry,
  conflicts,
  localEvents,
  remoteEvents,
  dispenser,
}: {
  registry: TEventRegistry;
  conflicts: TConflictDict;
  localEvents: TEvent[];
  remoteEvents: TEvent[];
  dispenser: TDispenser;
}) {
  registerChanges(registry, localEvents, remoteEvents);

  const applyOnClient: TEvent[] = [];
  const applyOnServer: TEvent[] = [];

  collectConflicts({
    conflictsCollection: conflicts,
    registry,
    dispenser,
    localEvents,
    remoteEvents,
    applyOnClient,
    applyOnServer,
  });

  conflicts = autoResolve(conflicts);

  return {
    registry,
    conflicts,
    applyOnClient,
    applyOnServer,
    dispenser,
  };
}

// After manual choosing
export function transformConflictsToApply(conflicts: TConflictDict) {
  const resolvedForClient: TEvent[] = [];
  const resolvedForServer: TEvent[] = [];

  Object.values(conflicts).forEach((conflict) => {
    const remoteEvent = conflict.remoteEvents[conflict.remoteEvents.length - 1];
    const localEvent = conflict.localEvents[conflict.localEvents.length - 1];

    switch (conflict.eventType) {
      case PROJECT_META_UPDATE:
      case NODE_META_UPDATE: {
        if (conflict.resolvedTo === 'local') {
          const builtEvent = getDeepCopy<TEvent>(localEvent);
          resolvedForServer.push(builtEvent);
        }

        if (conflict.resolvedTo === 'remote') {
          const builtEvent = getDeepCopy<TEvent>(remoteEvent);
          resolvedForClient.push(builtEvent);
        }

        return;
      }

      case NODE_PARENT_UPDATE: {
        if (conflict.resolvedTo === 'local') {
          const builtEvent = getDeepCopy<TEvent>(localEvent);
          builtEvent.acts[0] = remoteEvent.acts[0];
          resolvedForServer.push(builtEvent);

          return;
        }

        if (conflict.resolvedTo === 'remote') {
          const builtEvent = getDeepCopy<TEvent>(remoteEvent);
          builtEvent.acts[0] = localEvent.acts[0];
          resolvedForClient.push(builtEvent);

          return;
        }

        return;
      }

      case NOTE_UPDATE_CONTENT: {
        if (conflict.resolvedTo === 'local') {
          resolvedForServer.push(getDeepCopy<TEvent>(localEvent));
        }

        if (conflict.resolvedTo === 'remote') {
          resolvedForClient.push(getDeepCopy<TEvent>(remoteEvent));
        }

        return;
      }

      default: {
        return;
      }
    }
  });

  return {
    resolvedForClient,
    resolvedForServer,
  };
}

export function prepareDeltaEventsBeforeCount({
  events,
  notes,
}: {
  events: TEvent[];
  notes: TShortNoteDict;
}) {
  return events.map((event) => {
    event.acts.map((act) => {
      if (act.type === NOTE_UPDATE_CONTENT) {
        const noteContent = notes[act.payload.entityId];

        if (!noteContent) {
          act.payload.content = '';
          return;
        }

        act.payload.content = noteContent.content;
        act.payload.hash = noteContent.hash;
      }
    });

    return event;
  });
}
