import {
  TCollection,
  TEntityMeta,
  TEntityRef,
  TNote,
  TProject,
  TProjectMeta,
} from '@ws/shared/types';

export const PROJECT_CREATE = 'PROJECT_CREATE';
export interface IProjectCreateAct {
  project: TProject;
}

export const PROJECT_META_UPDATE = 'PROJECT_META_UPDATE';
export interface IProjectMetaUpdateAct {
  projectId: string;
  meta: Partial<TProjectMeta>;
}

export const NOTE_CREATE = 'NOTE_CREATE';
export interface INoteCreateAct {
  note: TNote;
}

export const COLLECTION_CREATE = 'COLLECTION_CREATE';
export interface ICollectionCreateAct {
  collection: TCollection;
}

export const NODE_META_UPDATE = 'NODE_META_UPDATE';
export interface INodeMetaUpdateAct {
  ref: TEntityRef;
  meta: Partial<TEntityMeta>;
}

export const NODE_PARENT_UPDATE = 'NODE_PARENT_UPDATE';
export interface INodeParentUpdateAct {
  ref: TEntityRef;
  parentId: string;
}

export const NOTE_UPDATE_CONTENT = 'NOTE_UPDATE_CONTENT';
export interface INoteContentUpdateAct {
  entityId: string;
  content: string;
  hash: string;
}

export enum CHILD_MOVE_TYPE {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export const COLLECTION_UPDATE_CHILD = 'COLLECTION_UPDATE_CHILD';
export interface ICollectionChildUpdateAct {
  entityId: string;
  child: TEntityRef;
  move: CHILD_MOVE_TYPE;
  position: number;
}

export const NODE_REMOVE = 'NODE_REMOVE';
export interface INodeRemoveAct {
  ref: TEntityRef;
}
