import { useMutation } from '@tanstack/react-query';
import { Button, Input, Select } from '@ws/shared/components';
import { User20 } from '@ws/shared/icons';
import { ICreatePreviewProps } from '@ws/shared/resources';
import { TCollection, TNote, TCompiled } from '@ws/shared/types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { TAppState } from '../../../redux';
import { getResourceManager } from '../../../resources';
import { getNowAndAName } from '../../../utils/getNowAndAName';

import styles from './PreviewCreator.module.css';

const accessGroupOptions = [
  { label: 'Могут комментировать', value: 'reviewer' },
  { label: 'Могут просматривать', value: 'read_only' },
];

interface PreviewCreatorProps {
  entity: TCollection | TNote;
  compiled: TCompiled;
  onClose(): void;
  onCreationFinish(): void;
}

export function PreviewCreator({
  entity,
  compiled,
  onClose,
  onCreationFinish,
}: PreviewCreatorProps) {
  const { mutate, isLoading } = useMutation(
    (params: ICreatePreviewProps) => {
      return getResourceManager().PreviewService.createPreview(params);
    },
    {
      networkMode: 'offlineFirst',
      onSuccess() {
        onCreationFinish();
      },
    },
  );
  const { project } = useSelector((state: TAppState) => state.editor);
  const inputRef = useRef<HTMLInputElement>(null);
  const { augmentedName } = getNowAndAName();
  const [text, setText] = useState(`${entity.meta.name} ${augmentedName}`);
  const [accessGroup, setAccessGroup] = useState<string>('read_only');

  useEffect(() => {
    inputRef.current?.select();
  }, []);

  async function handleCreateClick() {
    if (!project) {
      return;
    }

    mutate(
      {
        projectId: project.id,
        entityId: entity.id,
        title: text,
        accessType: 'public',
        accessGroup,
        ...compiled,
      },
      {
        onSuccess() {
          onClose();
        },
      },
    );
  }

  return (
    <div className={styles['preview-creator']}>
      <Input
        ref={inputRef}
        value={text}
        onChange={(event) => setText(event.target.value)}
        label="Название"
        wrapperClassName={styles['preview-creator__input']}
      />

      <p className={styles['preview-creator__settings']}>Настройки доступа</p>

      <div className={styles['preview-creator__selections']}>
        <div className={styles['preview-creator__selection-item']}>
          <div className={styles['preview-creator__selection-user']}>
            <span className={styles['preview-creator__selection-user-icon']}>
              <User20 />
            </span>
            <span className={styles['preview-creator__selection-text']}>
              Все, у кого есть ссылка
            </span>
          </div>

          <Select
            value={accessGroup}
            onChange={(newValue) => setAccessGroup(newValue)}
            options={accessGroupOptions}
          />
        </div>
      </div>

      <div className={styles['preview-creator__buttons']}>
        <Button color="secondary" className={styles['button']} onClick={handleCreateClick}>
          Сохранить
        </Button>
        <Button
          disabled={isLoading}
          color="primary"
          className={styles['button']}
          onClick={handleCreateClick}
        >
          Сохранить и перейти к предпоказу
        </Button>
      </div>
    </div>
  );
}
